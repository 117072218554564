var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('h2',[_vm._v(_vm._s(_vm.$t('message_board.head')))])]),_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',[_c('v-card-title',[_vm._v(" "+_vm._s(_vm.$t('message_board.head_list'))+" ")]),_c('v-card-actions',{staticClass:"pb-4 d-flex align-end"},[_c('v-spacer')],1),_c('v-card-actions',{staticClass:"pt-0"},[_c('v-data-table',{staticStyle:{"width":"100%"},attrs:{"mobile-breakpoint":"0","headers":_vm.headers,"items":_vm.itemsData,"no-data-text":_vm.$t('not_found'),"no-results-text":_vm.$t('not_found'),"options":_vm.pagination,"server-items-length":_vm.pagination.totalItems,"items-per-page":_vm.pagination.rowsPerPage,"page":_vm.pagination.page,"footer-props":{
            'items-per-page-options': [10, 25, 30, 40, 50],
          },"loading":_vm.loading,"search":_vm.search},on:{"update:options":function($event){_vm.pagination=$event},"update:page":function($event){return _vm.$set(_vm.pagination, "page", $event)}},scopedSlots:_vm._u([{key:"item.status",fn:function(ref){
          var item = ref.item;
return [(item.status && item.status === 'approve')?_c('span',{staticClass:"success--text"},[_vm._v(_vm._s(_vm.$t('message_board.approved')))]):(item.status && item.status === 'notApprove')?_c('span',{staticClass:"error--text"},[_vm._v(_vm._s(_vm.$t('message_board.not_approved')))]):_c('span',{staticClass:"warning--text"},[_vm._v(_vm._s(_vm.$t('message_board.wait_approved')))])]}},{key:"item.created_at",fn:function(ref){
          var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.formatDate(item.created_at)))])]}},{key:"item.actions",fn:function(ref){
          var item = ref.item;
return [_c('div',{staticClass:"d-flex flex-row align-center justify-center ma-0"},[_c('v-btn',{staticClass:"success mx-1",attrs:{"disabled":item.status !== 'wait'},on:{"click":function($event){return _vm.uppdateMessageBoard('approve', item)}}},[_c('v-icon',{attrs:{"medium":"","color":"white"}},[_vm._v("mdi-check")])],1),_c('v-btn',{staticClass:"error mx-1",attrs:{"disabled":item.status !== 'wait'},on:{"click":function($event){return _vm.uppdateMessageBoard('notApprove', item)}}},[_c('v-icon',{attrs:{"medium":"","color":"white"}},[_vm._v("mdi-close")])],1)],1)]}}],null,true)})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }